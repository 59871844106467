import Link from 'next/link';

import { REVIEWS_LIST } from '@/widgets/layouts/main/constants/reviews';

import { TextShimmerAnimation } from '@/shared/components/animation/text-shimmer';
import { Article } from '@/shared/components/article';
import { CallbackForm } from '@/shared/components/callback-form';
import ArrowIcon from '@/shared/components/icons/arrow-icon';
import HeartIcon from '@/shared/components/icons/heart-icon';
import { Marquee } from '@/shared/components/marquee';
import { Button } from '@/shared/components/ui/button';

import { ReviewCard } from '../reviews/review-card';

interface CallToActionProps {
  header?: string;
}

export const CallToAction = (props: CallToActionProps) => {
  const { header = 'Не можете определиться?' } = props;

  return (
    <Article>
      <div className="relative flex w-full flex-col items-center justify-center overflow-hidden rounded-xl border p-4 py-6 sm:rounded-[2rem] sm:p-10 sm:py-14">
        <div className="absolute rotate-[35deg]">
          <Marquee>
            {REVIEWS_LIST.map((review, index) => (
              <ReviewCard
                key={index}
                text={review.text}
                author={review.author}
                textClass="line-clamp-3"
                className="sm:shadow-sm lg:min-w-24"
              />
            ))}
          </Marquee>

          <Marquee reverse>
            {REVIEWS_LIST.map((review, index) => (
              <ReviewCard
                key={index}
                text={review.text}
                author={review.author}
                textClass="line-clamp-3"
                className="sm:shadow-sm lg:min-w-24"
              />
            ))}
          </Marquee>

          <div className="hidden sm:block">
            <Marquee>
              {REVIEWS_LIST.map((review, index) => (
                <ReviewCard
                  key={index}
                  text={review.text}
                  author={review.author}
                  textClass="line-clamp-3"
                  className="sm:shadow-sm lg:min-w-24"
                />
              ))}
            </Marquee>

            <Marquee reverse>
              {REVIEWS_LIST.map((review, index) => (
                <ReviewCard
                  key={index}
                  text={review.text}
                  author={review.author}
                  textClass="line-clamp-3"
                  className="sm:shadow-sm lg:min-w-24"
                />
              ))}
            </Marquee>

            <Marquee>
              {REVIEWS_LIST.map((review, index) => (
                <ReviewCard
                  key={index}
                  text={review.text}
                  author={review.author}
                  textClass="line-clamp-3"
                  className="sm:shadow-sm lg:min-w-24"
                />
              ))}
            </Marquee>

            <Marquee reverse>
              {REVIEWS_LIST.map((review, index) => (
                <ReviewCard
                  key={index}
                  text={review.text}
                  author={review.author}
                  textClass="line-clamp-3"
                  className="sm:shadow-sm lg:min-w-24"
                />
              ))}
            </Marquee>
          </div>
        </div>

        <div className="z-10 grid size-24 place-items-center rounded-[2rem] border bg-white/10 p-3 shadow-2xl backdrop-blur-md dark:bg-black/10 lg:size-32">
          <HeartIcon className="h-5/6 w-5/6" />
        </div>

        <div className="z-10 mt-4 flex flex-col items-center text-center text-black dark:text-white">
          <h2 className="text-2xl font-bold lg:text-4xl">{header}</h2>

          <p className="mt-2 max-w-2xl text-sm sm:text-base">
            Запишитесь на бесплатную консультацию, где вы с учителем определите уровень английского, поставите цели и
            выберете подходящий тип занятий.
          </p>

          <CallbackForm>
            <Button
              size="link"
              className="group mt-4 flex items-center justify-center rounded-[2rem] border border-brand-accent-light bg-background px-6 text-sm font-medium shadow-sm transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
            >
              <TextShimmerAnimation className="flex items-center justify-center">
                Бесплатная консультация
                <ArrowIcon className="ml-1 size-4 transition-all duration-300 ease-out group-hover:translate-x-1" />
              </TextShimmerAnimation>
            </Button>
          </CallbackForm>
        </div>

        <div className="absolute inset-x-0 bottom-0 h-full bg-gradient-to-b from-transparent to-white to-60% dark:to-black sm:to-70%"></div>
      </div>
    </Article>
  );
};
